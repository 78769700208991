<template>
  <div class="tub-carousel">
    <h3>
      Notre sélection de
      <span>
        biens rentables
        <template v-if="city && postalCode">à {{ city }}</template>
      </span>
    </h3>
    <tub-category-selector
      class="selector"
      :active-category-slug="activeCategorySlug"
      :categories="categories"
    />
    <client-only>
      <article-swipe-container class="offers-list" :items="offersToDisplay">
        <template #item="{ item }">
          <tub-offer-card :key="item.id" :offer="item" :skeleton="displaySkeleton" />
        </template>
      </article-swipe-container>
    </client-only>
  </div>
</template>

<script setup>
import { useRemoteDatasource } from '@hz/ui-kit'

const props = defineProps({
  city: {
    type: String,
    required: false,
  },
  postalCode: {
    type: String,
    required: false,
  },
})

const nbOffersToDisplay = 8

const tubApi = useTubApi()

const { data: pageContent } = await useAsyncData(() => tubApi.getCategoryPageContent())

const useCityFacet = computed(() => {
  return !!props.city && !!props.postalCode
})

const categories = computed(() =>
  pageContent.value.categories.map((category) => {
    const navigation = {
      name: useCityFacet.value ? 'biens-rentables-category-facet' : 'biens-rentables-category',
      params: useCityFacet.value
        ? { category: category.slug, city: props.city, postalCode: props.postalCode }
        : { category: category.slug },
    }

    return {
      title: category.title,
      icon: category.icon.content,
      slug: category.slug,
      navigation,
    }
  }),
)

const firstCategory = computed(() => pageContent.value.categories[0])

const activeCategorySlug = computed(() => {
  return firstCategory.value.slug
})

const displaySkeleton = ref(true)
const offers = ref([])

const offersToDisplay = computed(() => {
  if (displaySkeleton.value) {
    return Array.from({ length: nbOffersToDisplay }, (i) => ({ id: `skeleton-${i}` }))
  }

  return offers.value
})

onMounted(async () => {
  const dataSource = useRemoteDatasource(tubApi.find)

  let sorting = '-rendement'
  const filters = tubApi.getDataSourceFilters(firstCategory.value.filters)

  if (firstCategory.value.filters.sorting) {
    sorting = firstCategory.value.filters.sorting
  }

  if (useCityFacet.value) {
    filters.locations = [{ cp: props.postalCode, ville: props.city, distance: 'all-city' }]
  }

  dataSource.setFilters(filters)

  const initialSortDirection = sorting.startsWith('-') ? 'desc' : 'asc'
  const initialSortName = sorting.startsWith('-') ? sorting.slice(1) : sorting
  dataSource.sortBy(initialSortName, initialSortDirection)

  dataSource.setPage(1)
  dataSource.setPageSize(nbOffersToDisplay)
  await dataSource.load()

  offers.value = dataSource.data.value
  displaySkeleton.value = false
})
</script>

<style lang="less" scoped>
.tub-carousel {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    color: var(--ds-color-gray-100);
    font-size: 26px;
    font-weight: var(--ds-weight-semi-bold);

    span {
      color: var(--ds-color-primary-100);
    }
  }

  .selector {
    border-bottom: 1px solid var(--ds-color-gray-10);
  }
}

:deep(.scroll-container) .wrapper {
  gap: 32px;
}

:deep(.scroll-arrow) {
  &.left {
    left: 18px;
  }

  &.right {
    right: 18px;
  }
}

.offers-list {
  :deep(.tub-offer-card) {
    margin-left: 32px;

    &:last-child {
      margin-right: 32px;
    }
  }
}
</style>
